import React, { useContext } from "react"
import Button from "shared/components/Button"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import styled from "styled-components"
import { Formik, Form, Field } from "formik"

import Select from "shared/components/FormSelect"
import CurrentUserContext from "shared/utils/CurrentUserContext"
import useNotifications from "shared/hooks/useNotifications"
import { useQuery, useMutation } from "@apollo/client"
import usePageTitle from "mill/hooks/usePageTitle"
import FETCH_ACCOUNT_TOPICS from "mill/graphql/FetchAccountTopics"
import UPDATE_FAVOURITE_TOPICS from "mill/graphql/UpdateFavouriteTopics"
import { FavouriteTopicsSchema } from "mill/utils/schemas"

const TopicButton = styled(Button)`
  margin: 0 0.3rem 0.3rem 0;
`

const FavouriteTopics = props => {
  usePageTitle("Favourite Topics")
  const { currentUser: viewer } = useContext(CurrentUserContext)
  const { displayNotification } = useNotifications()
  const { loading, error, data } = useQuery(FETCH_ACCOUNT_TOPICS)
  const [updateFavouriteTopics] = useMutation(UPDATE_FAVOURITE_TOPICS, {
    onCompleted: res => {
      if (res.updateFavouriteTopics.errors) {
        displayNotification({
          text: res.updateFavouriteTopics.errors
        })
      } else {
        displayNotification({
          text: "Favourite topics updated",
          style: "success"
        })
      }
    }
  })
  const topics = data?.questionTopics?.nodes || []
  const topicOptions = topics.map(t => {
    return {
      value: t.id,
      label: t.name
    }
  })

  const favouriteTopics = topics?.filter(t => t.favourite) || []
  const favouriteOptions = favouriteTopics.map(t => {
    return {
      value: t.id,
      label: t.name
    }
  })

  if (loading) return null

  return (
    <Formik
      initialValues={{
        topicIds: favouriteTopics.map(o => o.id)
      }}
      validationSchema={FavouriteTopicsSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true)
        updateFavouriteTopics({
          variables: {
            accountId: viewer?.account?.id,
            topicIds: values.topicIds
          }
        })
      }}>
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Box paddingTop="medium">
            <Heading level={2}>Favourite Topics</Heading>
            <Text>
              Add or remove up to 10 favourite topics for your account.
            </Text>
            <Form>
              <Box paddingTop="small" paddingBottom="small">
                <Field
                  name="topicIds"
                  type="select"
                  component={Select}
                  options={topicOptions}
                  isMulti
                  isOptionDisabled={option => values.topicIds.length >= 10}
                />
              </Box>
              <Button type="submit" color="secondary" label="Update" />
            </Form>

            <Box paddingTop="medium">
              <Box paddingBottom="xsmall">
                <Heading level={3}>All account topics</Heading>
              </Box>
              {topics.map(t => {
                if (t.favourite) return
                return (
                  <TopicButton
                    onClick={() => {
                      if (values.topicIds.length >= 10) {
                        return
                      }
                      setFieldValue(
                        "topicIds",
                        [...new Set([...values.topicIds, t.id])],
                        true
                      )
                    }}
                    size="xsmall"
                    variant="hollow"
                    key={`topic-${t.id}`}
                    label={t.name}
                  />
                )
              })}
            </Box>
          </Box>
        )
      }}
    </Formik>
  )
}

export default FavouriteTopics